<template>
  <div>
    <div class="model_wrap" v-if="closeVideo">
      <video src="https://unre-ucl360-services.oss-cn-hangzhou.aliyuncs.com/www-video/about.9dfbb7e7.mp4" controls autoplay></video>
    </div>
    <div class="about_page" @click.stop="closeVideo = false">
      <div class="banner_wrap">
        <p class="title fadeInLeft slow animated wow">让空间数智化更简单</p>
        <p class="des fadeInRight slow animated wow">创新奋斗，追求卓越，合作共赢</p>
      </div>
      <div class="des_wrap">
        <p class="title">公司简介</p>
        <div class="des_content">
          <div class="left fadeInLeft  slow animated wow">
            <img
              src="@/assets/v_bg.png"
              alt=""
              @click.stop="closeVideo = true"
            />
          </div>
          <div class="right fadeInRight  slow animated wow">
            <p>
              盎锐科技成立于2017年，是国内专注于智能测量领域的建筑机器人服务厂商。公司以3D智能视觉、IoT5G、云计算等高新技术赋能建筑行业信息化和智能化，为建筑企业提供三维扫描硬件，建模、测量、数据智能分析软件和云服务的全栈式产品和服务。
            </p>
            <p class="mb">
              盎锐科技基于自主研发的测量机器人提供智能测量和BIM应用两种产品服务。依托国际领先的技术储备和实力致力让每一个建筑企业都用上UNRE的产品和服务。
            </p>
            <p>
              公司总部位于上海，在深圳、成都、香港、江苏、北京等地设有分公司，员工人数近百人，八成为研发人员申请的专利超180项，获批发明专利达55项。
            </p>
            <p>
              目前已经服务超过200家客户，涉及客户群体主要为开发商、施工单位、装修公司、工程管理平台软件服务商等。
            </p>
          </div>
        </div>

        <div class="line">
          <div
            class="item fadeInUp  slow animated wow"
            data-wow-offset="100"
            data-wow-delay=".2s"
          >
            <p>TOP</p>
            <p>国内第一家自研测量机器人的企业</p>
          </div>
          <div
            class="item fadeInUp  slow animated wow"
            data-wow-offset="100"
            data-wow-delay=".2s"
          >
            <p>{{ num1 }} <span>+</span></p>
            <div></div>
            <p>专利申请超过210项</p>
          </div>
          <div
            class="item  fadeInUp  slow animated wow"
            data-wow-offset="100"
            data-wow-delay=".2s"
          >
            <p>{{ num2 }}</p>
            <p>发明专利申请180项，已获批55项</p>
          </div>
          <div
            class="item  fadeInUp  slow animated wow"
            data-wow-offset="100"
            data-wow-delay=".2s"
          >
            <p>{{ num3 }}+</p>
            <p>全国累计服务超过200家企业</p>
          </div>
        </div>
      </div>

      <div class="culture_wrap">
        <p class="title">企业文化</p>
        <div class="content  fadeInUp  slow animated wow">
          <div class="item">
            <img src="@/assets/about_icon_1.png" alt="" />
            <p class="des_t">企业使命</p>
            <p>让空间数智化</p>
            <p>更简单</p>
          </div>
          <div class="item" >
            <img src="@/assets/about_icon_2.png" alt="" />
            <p class="des_t">企业愿景</p>
            <p>让每个建筑企业</p>
            <p>都用上UNRE产品和服务</p>
          </div>
          <div class="item">
            <img src="@/assets/about_icon_3.png" alt="" />
            <p class="des_t">企业价值观</p>
            <p>创新奋斗，追求卓越</p>
            <p>合作共赢</p>
          </div>
        </div>
      </div>

      <div class="year_wrap">
        <p class="title">发展历程</p>
        <div class="menu">
          <div
            @click="showYear(index)"
            class="menu_item"
            :class="{ active: activeYear === index }"
            v-for="(item, index) in year"
            :key="index"
          >
            {{ item.menu }}
          </div>
        </div>
        <div class="m_menu">
          <img class="contol" src="@/assets/down.png" alt="" />
          <div class="scoll_content">
            <div
              @click="showYear(index)"
              class="menu_item"
              :class="{ active: activeYear === index }"
              v-for="(item, index) in year"
              :key="index"
            >
              {{ item.menu }}
            </div>
          </div>
          <img class="contol" src="@/assets/down.png" alt="" />
        </div>
        <div
          class="content"
          v-for="(item, index) in year"
          :key="index"
          v-show="index === activeYear"
        >
          <img class="left" :src="item.img" alt="" />
          <div class="right">
            <p class="year">{{ item.title }}</p>
            <ul>
              <li v-for="(itm, idx) in item.des" :key="idx">
                <img src="@/assets/2043.png" alt="" srcset="" />
                {{ itm }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="good_wrap">
        <p class="title">美好盎锐</p>
        <div class="line line_top fadeInUp  slow animated wow">
          <div class="item">
            <img src="@/assets/about_gd_1.png" alt="" srcset="" />
            <p>五险一金</p>
          </div>
          <div class="item">
            <img src="@/assets/about_gd_2.png" alt="" srcset="" />
            <p>带薪假期</p>
          </div>
          <div class="item">
            <img src="@/assets/about_gd_3.png" alt="" srcset="" />
            <p>定期培训</p>
          </div>
          <div class="item">
            <img src="@/assets/about_gd_4.png" alt="" srcset="" />
            <p>餐费补贴</p>
          </div>
        </div>
        <div class="line fadeInUp  slow animated wow">
          <div class="item">
            <img src="@/assets/about_gd_5.png" alt="" srcset="" />
            <p>交通补贴</p>
          </div>
          <div class="item">
            <img src="@/assets/about_gd_6.png" alt="" srcset="" />
            <p>通讯补贴</p>
          </div>
          <div class="item">
            <img src="@/assets/about_gd_7.png" alt="" srcset="" />
            <p>多彩团队</p>
          </div>
          <div class="item">
            <img src="@/assets/about_gd_8.png" alt="" srcset="" />
            <p>节日福利</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from "wowjs"
export default {
  name: "about",
  data() {
    return {
      closeVideo: false,
      year: [
        {
          menu: "2017年",
          title: "2017",
          img: require("@/assets/about_year_2017.png"),
          des: ["盎锐科技成立"],
        },
        {
          menu: "2019年",
          title: "2019",
          img: require("@/assets/about_year_2019.png"),
          des: [
            "历经三代产品迭代可量产机面世",
            "获国家高新技术企业称号",
            "获科技成果评价报告证书",
          ],
        },
        {
          menu: "2020年",
          title: "2020",
          img: require("@/assets/about_year_2020.png"),
          des: ["盎锐测量机器人被建筑单位批量使用"],
        },
        {
          menu: "2021年",
          title: "2021",
          img: require("@/assets/about_year_2021.png"),
          des: ["获质量管理体系认证、环境管理体系认证、安全管理体系认证"],
        },
        {
          menu: "2022年",
          title: "2022",
          img: require("@/assets/about_year_2022.png"),
          des: ["累计服务企业超过200家"],
        },
      ],
      activeYear: 0,
      num1: 0,
      num2: 0,
      num3: 0,
    };
  },
  components: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
    var isScroll = false;
    window.addEventListener("scroll", () => {
      if (!isScroll) {
        console.log(1);
        var timer1 = setInterval(() => {
          this.num1++;
          if (this.num1 === 210) {
            clearInterval(timer1);
          }
        }, 5);
        var timer2 = setInterval(() => {
          this.num2++;
          if (this.num2 === 180) {
            clearInterval(timer2);
          }
        }, 5);
        var timer3 = setInterval(() => {
          this.num3++;
          if (this.num3 === 200) {
            clearInterval(timer3);
          }
        }, 5);
      }
      isScroll = true
    });
  },
  methods: {
    showYear(index) {
      this.activeYear = index;
    },
  },
};
</script>

<style lang="less">
.model_wrap {
  z-index: 9999;
  width: 5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  video {
    width: 100%;
  }
}
.about_page {
  background: #edf1f7;
overflow: hidden;
  .breadcrumb_wrap {
    margin-bottom: 0.32rem;
  }
  .banner_wrap {
    background-image: url(../assets/about_bg.png);
    background-size: cover;
    width: 10rem;
    height: 2.88rem;
    padding-top: 0.96rem;
    .title {
      display: block;
      margin-bottom: 0.1rem;
      color: #fcfcfc;
      font-size: 0.21rem;
    }

    .des {
      color: #e6e6e6;
      font-size: 0.13rem;
    }
  }

  .des_wrap {
    padding: 0.32rem 1.05rem;
    background-image: url(../assets/about_des_bg.png);
    background-size: cover;
    width: 10rem;
    height: 4.42rem;
    .title {
      font-size: 0.19rem;
      font-weight: 600;
      color: #4d4d4d;
      margin-bottom: 0.25rem;
    }
    .des_content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.4rem;
      .left {
        width: 3.69rem;
        img {
          width: 100%;
          cursor: pointer;
        }
        video {
          width: 100%;
        }
      }
      .right {
        color: #4d4d4d;
        width: 3.94rem;
        text-align: left;

        font-size: 0.095rem;
        line-height: 0.16rem;
        color: #666666;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          text-indent: 30px;
        }
        .mb {
          margin-bottom: 0.1rem;
        }
      }
    }
    .line {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .item {
        text-align: center;
        width: 1.5rem;
        p {
          margin-bottom: 0.08rem;
          color: #02aeec;
          font-size: 0.25rem;
          &:last-child {
            color: #666666;
            font-size: 0.095rem;
          }
        }
      }
    }
  }

  .culture_wrap {
    display: flex;
    background: #fcfcfc;
    padding: 0.32rem 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .content {
      display: flex;
      justify-content: center;
    }
    .title {
      font-size: 0.19rem;
      font-weight: 600;
      color: #4d4d4d;
      margin-bottom: 0.25rem;
    }
    .item {
      width: 2.5rem;
      height: 2.42rem;
      background: #ffffff;
      box-shadow: 0rem 0.03rem 0.13rem 0.01rem rgba(102, 102, 102, 0.08);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:nth-child(2){
        margin: 0 0.1rem;
      }
      img {
        margin-bottom: 0.1rem;
        width: 0.31rem;
      }
      p {
        margin-bottom: 0.08rem;

        color: #808080;

        font-size: 0.09rem;
        &.des_t {
          color: #4D4D4D;
font-size: 0.13rem;
        }
      }
    }
  }

  .year_wrap {
    background-image: url(../assets/about_year_bg.png);
    background-size: cover;
    width: 10rem;
    height: 4.52rem;
    padding: 0.32rem 0 0.82rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 0.19rem;
      font-weight: 600;
      color: #fff;
      margin-bottom: 0.25rem;
    }
    .menu {
      display: flex;
      margin-bottom: 0.3rem;
      .menu_item {
        width: 0.72rem;
        height: 0.29rem;
        margin: 0 0.02rem;
        cursor: pointer;
        line-height: 0.27rem;
        color: rgba(255, 255, 255, 0.8);
        font-size: 0.1rem;
        &:hover,
        &.active {
          background: rgba(2, 174, 236, 0.5);
          border-radius: 0.03rem 0.03rem 0.03rem 0.03rem;
          border: 0.01rem solid #02aeec;
          color: #fcfcfc;
          font-size: 0.13rem;
        }
      }
    }
    .m_menu {
      display: none;
    }
    .content {
      display: flex;
      text-align: left;
      .left {
        width: 3.98rem;
      }
      .right {
        width: 3.93rem;
        padding: 0.25rem;
        background: rgba(0, 0, 0, 0.4);
        .year {
          margin-bottom: 0.1rem;
          color: #02aeec;
          font-size: 0.29rem;
        }
        li {
          list-style: none;
          margin-bottom: 0.05rem;

          color: #e6e6e6;
        }
        img {
          width: 0.08rem;
        }
      }
    }
  }

  .good_wrap {
    padding: 0.32rem 1.05rem;
    background-image: url(../assets/about_gd_bg.png);
    background-size: cover;
    width: 10rem;
    height: 4.01rem;
    .title {
      font-size: 0.19rem;
      color: #4d4d4d;
      font-weight: 600;
    }
    .line {
      display: flex;
      text-align: center;
      justify-content: center;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #4d4d4d;
      font-size: 0.13rem;
      margin: 0 0.2rem;
      img {
        width: 1.36rem;
      }
      p {
        margin-top: -0.2rem;
      }
    }
  }
}
@media screen and (max-width: 1020px) {
  .model_wrap {
    z-index: 9999;
    width: 10rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    video {
      width: 100%;
    }
  }
  .about_page {
    .banner_wrap {
      background-image: url(../assets/about_bg_m.png);
      background-size: cover;
      width: 10rem;
      height: 16.79rem;
      padding-top: 6.31rem;
      .title {
        display: none;
      }
      .des {
        display: none;
      }
    }

    .des_wrap {
      padding: 1.2rem 0.43rem;
      background: #fff;
      height: auto;
      background-image: url(../assets/about_des_bg_m.png);
      background-size: cover;
      .title {
        font-weight: 600;
        color: #333333;
        font-size: 0.45rem;
        margin-bottom: 0.56rem;
      }
      .des_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        video {
          width: 100%;
        }
        .left {
          width: 100%;
          margin-bottom: 0.8rem;
        }
        .right {
          width: 100%;
          color: #666666;
          font-size: 0.32rem;
          line-height: 0.64rem;
        }
      }
      .line {
        flex-wrap: wrap;
        margin-bottom: 0;
        .item {
          width: 50%;
          margin-bottom: 0.45rem;
          p {
            color: #02aeec;

            font-size: 0.48rem;
            &:last-child {
              color: #666666;

              font-size: 0.32rem;
            }
          }
        }
      }
    }

    .culture_wrap {
      display: flex;
      background: #fcfcfc;
      padding: 1.2rem 0rem;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .content {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .title {
        font-weight: 600;
        color: #333333;
        font-size: 0.45rem;
        margin-bottom: 0.56rem;
      }
      .item {
        width: 33.3%;
        height: auto;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: #fcfcfc;
        box-shadow: none;
        img {
          margin-bottom: 0.24rem;
          width: 0.8rem;
        }
        p {
          margin-bottom: 0.16rem;

          color: #808080;

          font-size: 12px;
          &.des_t {
          color: #4D4D4D;
font-size: 14px;
        }
        }
      }
    }

    .year_wrap {
      background-image: url(../assets/about_year_bg_m.png);
      background-size: cover;
      width: 10rem;
      height: 14.67rem;
      padding: 1.2rem 0.43rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 0.45rem;
        font-weight: 600;
        color: #fff;
        margin-bottom: 0.56rem;
      }
      .m_menu {
        display: flex;
        margin-bottom: 0.85rem;
        width: 80%;

        position: relative;
        .scoll_content {
          overflow-x: scroll;
          overflow-y: hidden;
          display: flex;
        }
        .contol {
          width: 0.8rem;
          height: 0.8rem;
          padding: 0.2rem;
          position: absolute;
          &:first-child {
            // top: 50%;
            left: -1rem;
            // transform: translateY(-50%);
            transform: rotate(90deg);
          }
          &:last-child {
            // top: 50%;
            right: -1rem;
            // transform: translateY(-50%);
            transform: rotate(-90deg);
          }
        }
        .menu_item {
          width: 1.84rem;
          height: 0.73rem;
          flex: none;
          margin: 0 0.4rem;
          cursor: pointer;
          line-height: 0.73rem;
          color: rgba(255, 255, 255, 0.8);

          font-size: 0.27rem;
          &:hover,
          &.active {
            background: rgba(2, 174, 236, 0.5);

            border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;

            border: 0.03rem solid #02aeec;
            color: #fcfcfc;

            font-size: 0.32rem;
          }
        }
      }
      .menu {
        display: none;
      }
      .content {
        display: flex;
        text-align: left;
        flex-direction: column;
        .left {
          width: 100%;
        }
        .right {
          width: 100%;
          padding: 0.48rem;
          background: rgba(0, 0, 0, 0.4);
          .year {
            margin-bottom: 0.1rem;
            color: #02aeec;
            line-height: 0.86rem;
            font-size: 0.43rem;
          }
          li {
            list-style: none;
            margin-bottom: 0.05rem;

            font-size: 0.27rem;
            color: #e6e6e6;
          }
          img {
            width: 0.21rem;
          }
        }
      }
    }

    .good_wrap {
      padding: 0.64rem 1.3rem 0.85rem;
      background-image: url(../assets/about_gd_bg_m.png);
      background-size: cover;
      width: 10rem;
      height: 7.28rem;
      .title {
        font-weight: 600;
        color: #333333;
        font-size: 0.45rem;
        margin-bottom: 0.56rem;
      }
      .line {
        display: flex;
        text-align: center;
        justify-content: center;
        &.line_top {
          margin-top: -0.2rem;
        }
      }
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #4d4d4d;
        font-size:12px;
        font-weight: 600;
        margin: 0 0.2rem;
        img {
          width: 2.04rem;
          margin: 0 -0.2rem;
        }
      }
    }
  }
}
</style>
